import React, { useEffect, useState } from "react";
import type {
  GuidePreviewContentData as FrontendApiGuidePreviewContentData,
  ImageData,
} from "@xxl/frontend-api";
import type {
  GuidePreviewContentData as ContentApiGuidePreviewContentData,
  Image as ContentApiImage,
} from "@xxl/content-api";
import { getImageUrl } from "../../utils/xxl-image";
import Breakpoints from "../../styles/breakpoints.config";
import {
  ImageWrapper,
  Image,
  TeaserWrapper,
  Link,
  Details,
  ShowMoreArrow,
  GuideTitle,
  GuidePreamble,
  GuidePreambleWrapper,
} from "./GuideTeaser.styled";
import { MISSING_PRODUCT_IMAGE_URL } from "../../constants";
import { useSharedData } from "../../contexts/SharedData";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export type GuidePreviewContentDataWithTrackingTicket =
  | (FrontendApiGuidePreviewContentData & {
      ticket?: string;
    })
  | (ContentApiGuidePreviewContentData & {
      ticket?: string;
    });

type GuideTeaserProps = {
  guide: GuidePreviewContentDataWithTrackingTicket;
};

const getFullUrl = (
  image: ImageData | ContentApiImage | undefined,
  width: number,
  height: number
) => {
  return isNotNullOrUndefined(image) &&
    "baseUrl" in image &&
    image.baseUrl !== undefined
    ? getImageUrl(image, width, height)
    : isNotNullOrUndefined(image) && "url" in image && image.url !== undefined
      ? getImageUrl(image, width, height)
      : "";
};

export const GuideTeaser = ({ guide }: GuideTeaserProps): JSX.Element => {
  const {
    configuration: { guidesInterfix },
    requestMapping: { guides },
  } = useSharedData().data;
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(
      "categorizedUrl" in guide &&
        guide.categorizedUrl !== undefined &&
        guide.categorizedUrl.length > 0
        ? `/${guides}${guidesInterfix}${guide.categorizedUrl}`
        : `${guide.url ?? ""}`
    );
  }, [guide, guides, guidesInterfix]);

  const guideSrcSet = `
    ${getFullUrl(guide.image, 220, 320)} 220w,
    ${getFullUrl(guide.image, 322, 480)} 332w
  `;
  const guideSizes = `
    (max-width: ${Breakpoints.smallTablet}px) 220px,
    322px
  `;

  return (
    <TeaserWrapper>
      <Link href={url}>
        <ImageWrapper>
          <Image
            loading="lazy"
            srcSet={guideSrcSet}
            alt={`${guide.image?.alt ?? ""}`}
            sizes={guideSizes}
            src={getFullUrl(guide.image, 436, 250)}
            onError={(event: React.InvalidEvent<HTMLImageElement>) => {
              event.target.src = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
              event.target.srcset = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
            }}
          />
        </ImageWrapper>
        <Details>
          <GuideTitle>{guide.title}</GuideTitle>
          <GuidePreambleWrapper>
            <GuidePreamble>{guide.preamble}</GuidePreamble>
          </GuidePreambleWrapper>
          <ShowMoreArrow />
        </Details>
      </Link>
    </TeaserWrapper>
  );
};
