import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { ArrowRight } from "@xxl/icons";
import typography from "../../styles/theme/typography";

const { colors, spaces } = xxlTheme;

export const SlideWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  gap: ${spaces.miniMicro};

  ${MQ("smallTablet")} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const TeaserWrapper = styled.article`
  border: 1px solid var(--color-xxl-light-gray);
  height: 320px;

  ${MQ("smallTablet")} {
    height: 480px;
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
`;

export const ImageWrapper = styled.figure`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  object-fit: cover;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${colors.xxlWebBlack};
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding: ${spaces.smallRegular};
  width: 100%;
  box-sizing: border-box;
  height: 126px;
  ${MQ("smallTablet")} {
    height: 172px;
  }
`;

export const GuideTitle = styled.h3`
  margin: 0 0 ${spaces.miniMicro};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  ${typographyToCss(typography.mediumBold)};

  ${MQ("smallTablet")} {
    ${typographyToCss(typography.hugeBold)};
    line-height: 1.1;
  }
`;

export const GuidePreambleWrapper = styled.div`
  flex-grow: 1;
`;

export const GuidePreamble = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;

  &&& {
    min-height: auto;
  }

  ${typographyToCss(typography.smallRegular)};

  ${MQ("smallTablet")} {
    ${typographyToCss(typography.mediumRegular)};
  }
  line-height: 1.2;
`;

export const ShowMoreArrow = styled(ArrowRight)`
  width: 22px;
  height: 22px;

  ${MQ("smallTablet")} {
    width: 27px;
    height: 27px;
  }

  position: relative;
  left: -3px;
`;
